import useUser from "lib/useUser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userCompletedPredictionsSlice } from "_store/reducers";
import {
  getUserCompletedPredictionsSelector,
  getIsGettingPredictions,
  getIsGettingPredictionsError,
} from "_store/selectors";
import type { UserPredictionsActions } from "_store/types";

const { getPredictions } =
  userCompletedPredictionsSlice.actions as unknown as UserPredictionsActions;

type UserPredictionsHook = () => [boolean, any, any];

const useUserPredictions: UserPredictionsHook = () => {
  const fetchingPredictions = useSelector(getIsGettingPredictions);
  const userCompletedPredictions = useSelector(
    getUserCompletedPredictionsSelector
  );
  const userCompletedPredictionsError = useSelector(
    getIsGettingPredictionsError
  );
  const dispatch = useDispatch();
  const user = useUser();

  useEffect(() => {
    if (!user.loggedIn) {
      return;
    }

    if (
      !userCompletedPredictions.groups ||
      (userCompletedPredictions.groups.length === 0 &&
        !userCompletedPredictionsError)
    ) {
      dispatch(getPredictions(user));
    }
  }, [user.loggedIn, userCompletedPredictions, user.tournamentId]);

  return [
    fetchingPredictions,
    userCompletedPredictionsError,
    userCompletedPredictions,
  ];
};

export default useUserPredictions;
