import {
  getTranslations,
  getOdds,
  getPredictedWinner,
  getLocation,
  getTournamentConfig,
  getWheelUrlReq,
  saveUserPredictionsReq,
  getUserPredictionsReq,
  createLeagueReq,
  predictorGetUserReq,
  getSingleLeagueReq,
  leaveLeagueReq,
  joinLeagueReq,
} from './predictorRemoteCalls';

export {
  getTranslations,
  getOdds,
  getPredictedWinner,
  getLocation,
  getTournamentConfig,
  getWheelUrlReq,
  saveUserPredictionsReq,
  getUserPredictionsReq,
  createLeagueReq,
  predictorGetUserReq,
  getSingleLeagueReq,
  leaveLeagueReq,
  joinLeagueReq,
};
