import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    count: 0,
  },
  reducers: {
    inc(state) {
      state.count += 1;
    },
    dec(state) {
      state.count -= 1;
    },
  },
});

export const configSlice = createSlice({
  name: 'config',
  initialState: null,
  reducers: {
    set(_, action) {
      return action.payload;
    },
  },
});

export const userAgentSlice = createSlice({
  name: 'predictor:userAgent',
  initialState: '',
  reducers: {
    set(_, action) {
      return action.payload;
    },
  },
});

export const languageSlice = createSlice({
  name: 'predictor:language',
  initialState: '',
  reducers: {
    set(_, action) {
      return action.payload;
    },
  },
});

export const translationsSlice = createSlice({
  name: 'predictor:translations',
  initialState: {
    fetched: false,
    fetching: false,
  },
  reducers: {
    fetch(state) {
      state.fetching = true;
      state.fetched = false;
    },
    success(state, action) {
      return {
        ...action.payload,
        fetching: false,
        fetched: true,
      };
    },
    failure(state) {
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export const locationSlice = createSlice({
  name: 'predictor:location',
  initialState: {
    fetched: false,
    fetching: false,
  },
  reducers: {
    fetch(state) {
      state.fetching = true;
      state.fetched = false;
    },
    success(_, action) {
      return {
        ...action.payload,
        fetching: false,
        fetched: true,
      };
    },
    failure(state) {
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export const oddsSlice = createSlice({
  name: 'predictor:odds',
  initialState: {
    fetched: false,
    fetching: false,
  },
  reducers: {
    fetch(state) {
      state.fetching = true;
      state.fetched = false;
    },
    success(state, action) {
      return {
        ...action.payload,
        fetching: false,
        fetched: true,
      };
    },
    failure(state) {
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export const predictedWinnerSlice = createSlice({
  name: 'predictor:predictedWinner',
  initialState: {},
  reducers: {
    fetch(state) {
      state.fetching = true;
    },
    success(state, action) {
      return { ...action.payload, fetching: false };
    },
    failure(state) {
      state.fetching = false;
    },
  },
});

export const tournamentSlice = createSlice({
  name: 'predictor:tournament',
  initialState: {
    groups: [],
    fetching: null,
    fetched: false,
  },
  reducers: {
    fetch(state) {
      state.fetching = true;
      state.fetched = false;
    },
    success(state, action) {
      return {
        ...action.payload,
        fetching: false,
        fetched: true,
      };
    },
    failure(state) {
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export const stepsSlice = createSlice({
  name: 'predictor:steps',
  initialState: {
    current: 1,
  },
  reducers: {
    incrementCurrentStep(state) {
      state.current += 1;
    },
    decrementCurrentStep(state) {
      state.current -= 1;
    },
  },
});

function savePrediction(state, action, stateKey) {
  // mutate an object in place, redux-starter-kit feature
  // eslint-disable-next-line max-len
  state[stateKey].find((engagement) => engagement.home.title === action.payload.home.title).winner = action.payload.winner;

  if (state[stateKey].every((engagement) => engagement.winner !== null)) {
    state.error = {
      showError: false,
    };
  }
}

function getUserPredictionsInitialState() {
  const initial = {
    groupStage: [],
    luckyLosers: {
      name: '',
      teams: [],
    },
    roundOf16: [],
    quarterfinals: [],
    semifinals: [],
    finals: [],
    error: {
      showError: false,
    },
  };
  return initial;
}

export const userPredictionSlice = createSlice({
  name: 'predictor:userPrediction',
  initialState: getUserPredictionsInitialState(),
  reducers: {
    showError(state, action) {
      if (!action.payload.showError) {
        return;
      }
      state.error = action.payload;
    },
    saveGroupStagePrediction(state, action) {
      if (state.groupStage.length === 0) {
        state.groupStage = action.payload;
        return;
      }
      state.groupStage = state.groupStage.map((group) => {
        const groupToReplace = action.payload.find(
          (groupPrediction) => group.name === groupPrediction.name,
        );
        if (groupToReplace) {
          return {
            ...group,
            ...groupToReplace,
          };
        }
        return group;
      });
    },
    saveLuckyLosersPrediction(state, action) {
      const { teams } = action.payload;
      state.luckyLosers = {
        ...action.payload,
        teams,
      };
    },
    saveRO16Prediction(state, action) {
      state.roundOf16 = action.payload;
    },
    saveRO16WinnerPrediction(state, action) {
      savePrediction(state, action, 'roundOf16');
    },
    saveQFPrediction(state, action) {
      state.quarterfinals = action.payload;
    },
    saveQFWinnerPrediction(state, action) {
      savePrediction(state, action, 'quarterfinals');
    },
    saveSFPrediction(state, action) {
      state.semifinals = action.payload;
    },
    saveSFWinnerPrediction(state, action) {
      savePrediction(state, action, 'semifinals');
    },
    saveFinalsPrediction(state, action) {
      state.finals = action.payload;
    },
    saveFinalsWinnerPrediction(state, action) {
      savePrediction(state, action, 'finals');
    },
    clear() {
      return getUserPredictionsInitialState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(stepsSlice.actions.incrementCurrentStep, (state) => {
      state.error = {
        showError: false,
      };
    });

    builder.addCase(stepsSlice.actions.decrementCurrentStep, (state) => {
      state.error = {
        showError: false,
      };
    });
  },
});

const getUserWheelSliceInitialState = () => ({
  fetching: null,
  fetchingDone: null,
  wheel: null,
});
export const userWheelSlice = createSlice({
  name: 'predictor:userWheel',
  initialState: getUserWheelSliceInitialState(),
  reducers: {
    fetchWheel(state) {
      state.fetching = true;
      state.fetchingDone = false;
      state.wheel = null;
    },
    fetchWheelSuccess(state, action) {
      state.fetching = false;
      state.fetchingDone = true;
      state.wheel = action.payload;
    },
    fetchWheelFailure(state) {
      state.fetching = false;
      state.fetchingDone = true;
    },
    clear() {
      return getUserWheelSliceInitialState();
    },
  },
});

const getUserCompletedPredictionsInitialState = () => ({
  saveError: null,
  saving: null,
  fetching: null,
  predictions: {},
  error: null,
  savingDone: null,
  fetchingDone: null,
});

export const userCompletedPredictionsSlice = createSlice({
  name: 'predictor:userCompletedPrediction',
  initialState: getUserCompletedPredictionsInitialState(),
  reducers: {
    savePredictions(state) {
      state.saving = true;
      state.savingDone = false;
      state.saveError = null;
    },
    savePredictionsSuccess(state) {
      state.saving = false;
      state.savingDone = true;
      state.saveError = null;
    },
    savePredictionsFailure(state, action) {
      state.saving = false;
      state.savingDone = true;
      state.saveError = action.payload;
    },
    getPredictions(state) {
      state.fetching = true;
      state.fetchingDone = false;
      state.error = false;
    },
    getPredictionsSuccess(state, action) {
      state.predictions = action.payload;
      state.fetching = false;
      state.fetchingDone = true;
      state.error = null;
    },
    getPredictionsFailure(state, action) {
      state.fetching = false;
      state.error = action.payload;
      state.fetchingDone = true;
    },
    clearFlags(state) {
      state.error = null;
      state.saveError = null;
      state.saving = false;
      state.fetching = false;
      state.savingDone = false;
      state.fetchingDone = false;
    },
    resetPredictions(state) {
      const initState = getUserCompletedPredictionsInitialState();
      Object.entries(initState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
  },
});

const initialLeague = {
  fetching: null,
  leaving: null,
  error: null,
  redirect: null,
  Members: [],
  joining: false,
};

export const leaguesSlice = createSlice({
  name: 'predictor:leagues',
  initialState: {
    fetching: null,
    saving: null,
    leagues: [],
    error: null,
    league: initialLeague,
  },
  reducers: {
    createLeague(state) {
      state.error = null;
      state.saving = true;
    },
    createLeagueSuccess(state) {
      state.error = null;
      state.saving = false;
    },
    createLeagueFailure(state, action) {
      state.saving = false;
      state.error = action.payload;
    },
    getLeagues(state) {
      state.error = null;
      state.fetching = true;
    },
    getLeaguesSuccess(state, action) {
      state.error = null;
      state.fetching = false;
      state.leagues = action.payload.Leagues;
    },
    getLeaguesFailure(state, action) {
      state.fetching = false;
      state.error = action.payload;
    },
    getSingleLeague(state) {
      state.league.fetching = true;
    },
    getSingleLeagueSuccess(state, action) {
      state.league.fetching = false;
      state.league = { ...state.league, ...action.payload };
    },
    getSingleLeagueFailure(state, action) {
      state.league.fetching = false;
      state.league.error = action.payload;
    },
    leaveLeague(state) {
      state.league.leaving = true;
    },
    leaveLeagueSuccess(state) {
      state.league = {
        ...initialLeague,
        leaving: false,
      };
    },
    leaveLeagueFailure(state, action) {
      state.league.leaving = false;
      state.league.error = action.payload;
      state.league.redirect = null;
    },
    joinLeague(state) {
      state.league.joining = true;
    },
    joinLeagueSuccess(state, action) {
      state.league = {
        ...state.league,
        ...action.payload,
        joining: false,
        error: null,
      };
    },
    joinLeagueFailure(state, action) {
      state.league.joining = false;
      state.league.error = action.payload;
    },
    clearError(state) {
      state.league.error = null;
      state.error = null;
    },
  },
});

export const predictorRedirectSlice = createSlice({
  name: 'predictor:redirect',
  initialState: null,
  reducers: {
    clearRedirect() {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(leaguesSlice.actions.leaveLeagueSuccess, (_, action) => `/${action.payload.tournamentName}/prediction/myleagues`);
    builder.addCase(leaguesSlice.actions.createLeagueSuccess,  (_, action) => `/${action.payload.tournamentName}/league/${action.payload.Id}?openShare=true`);
    builder.addCase(leaguesSlice.actions.joinLeagueSuccess, (_, action) => `/${action.payload.tournamentName}/league/${action.payload.Id}`);
    builder.addCase(leaguesSlice.actions.getSingleLeagueFailure, (_, action) => `/${action.payload.tournamentName}`);
    builder.addDefaultCase((state) => state);
  },
});
