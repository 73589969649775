import React, { ReactElement } from 'react';

const PrizesIconSvg = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <path data-name="Rectangle 6992" fill="none" d="M0 0h25v25H0z" />
    <path data-name="Path 4506" d="m11.208 13.2 1.125-.854 1.115.844a.522.522 0 0 0 .813-.573l-.438-1.417 1.25-.99a.515.515 0 0 0-.333-.917h-1.459l-.448-1.4a.52.52 0 0 0-.99 0l-.458 1.4H9.917a.521.521 0 0 0-.323.927l1.24.99-.434 1.415a.522.522 0 0 0 .808.575zm-5.125 9.227a1.049 1.049 0 0 0 1.375.99l4.875-1.625 4.875 1.625a1.04 1.04 0 0 0 1.375-.99v-6.594a8.333 8.333 0 1 0-12.5 0zm6.25-18.344a6.25 6.25 0 1 1-6.25 6.25 6.255 6.255 0 0 1 6.25-6.25z" transform="translate(.167 .083)" fill="#fff" />
  </svg>
);

export default PrizesIconSvg;
