import React, { useState, useContext } from 'react';
import { LogoutModalContext } from '_constants/contexts';
import styled from 'styled-components';
import { useTranslation } from 'lib/i18n/useTranslation';
import { logout } from 'lib/auth/logout';
import Link from 'next/link';
import { userCompletedPredictionsSlice } from 'state/reducers';
import { useDispatch } from 'react-redux';
import PSPScreenInfo from '../ScreenInfo';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  position: relative;
  box-sizing: border-box;
`;

const Button = styled.button`
  margin-top: 50px;
  min-width: 300px;
  height: 55px;
  border-radius: 8px;
  background: ${(p) => p.theme.btnBg2};
  font-size: 16px;
  color: ${(p) => p.theme.activeText};
  letter-spacing: 0px;
  line-height: 1.19;
  font-weight: 500;
`;

// eslint-disable-next-line no-unused-vars
type SetIsModalOpen = (open: boolean) => void;
type Props = {
  children: React.ReactNode;
};

export const useLogoutModal = () => useContext(LogoutModalContext) as SetIsModalOpen;
const { resetPredictions } = userCompletedPredictionsSlice.actions;

const LogoutModalProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    (<LogoutModalContext.Provider value={setIsOpen}>
      {children}
      <PSPScreenInfo
        isOpen={isOpen}
        close={() => setIsOpen(false)}
      >
        <Container>
          <Link href="/" legacyBehavior>
            <Button onClick={() => {
              logout();
              dispatch(resetPredictions());
            }}
            >
              {t('sign_out')}

            </Button>
          </Link>
        </Container>
      </PSPScreenInfo>
    </LogoutModalContext.Provider>)
  );
};

export default LogoutModalProvider;
