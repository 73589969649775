/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
import trim from "lodash/trim";

declare global {
  interface Window {
    __PREDICTOR_BASE_PATH__: string;
    __WHEEL_BASE_PATH__: string;
  }
}

export const basePredictorApiUrl =
  (typeof window === "undefined"
    ? trim(process.env.PREDICTOR_BASE_PATH)
    : window.__PREDICTOR_BASE_PATH__) || "https://apigw.fotmob.com/game";

export const baseWheelApiUrl =
  (typeof window === "undefined"
    ? trim(process.env.WHEEL_BASE_PATH)
    : window.__WHEEL_BASE_PATH__) ||
  "https://apigw.fotmob.com/game/prediction/game";

// export const baseWheelApiUrl = 'https://as9zwfxay7.execute-api.eu-west-1.amazonaws.com/Stage/prediction/game';
// // Beta endpoint - Preditor API
// export const basePredictorApiUrl = 'https://as9zwfxay7.execute-api.eu-west-1.amazonaws.com/Stage/';

// Local debug endpoint - Predictor API
// export const basePredictorApiUrl = process.env.PREDICTOR_BASE_PATH ?? 'https://localhost:44318';

// Local debug endpoint - Wheel API
// export const baseWheelApiUrl = process.env.WHEEL_BASE_PATH ?? 'http://localhost:51987';
