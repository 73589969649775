/* eslint-disable react/display-name */
import { useRouter } from 'next/router';
import { getOrigin } from './getOrigin';

const xDefault = 'x-default';
const origin = getOrigin();

const toUrl = (...parts) => {
  try {
    return [...parts]
      .filter(Boolean)
      .join('/')
      .split('//')
      .join('/');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, parts);
    return '';
  }
};

const getAlternateLangUrl = (subPath) => (hreflang) => {
  const lang = hreflang !== xDefault ? hreflang : '';
  const href = toUrl(origin, lang, subPath);
  return (
    <link
      key={hreflang}
      rel="alternate"
      href={href}
      hrefLang={hreflang}
    />
  );
};

export const getAllAlternateLangUrl = (subPath = '') => {
  const { locale, locales } = useRouter();
  return (
    <>
      <link rel="canonical" href={toUrl(origin, locale, subPath)} />
      {[xDefault, ...locales].filter((l) => l !== locale).map(getAlternateLangUrl(subPath))}
    </>
  );
};
