import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHasFetchedTourmentConfig,
  getHasFetchedTranslations,
  getHasFetchedLocation,
  getHasFetchedOdds,
} from "state/selectors";
import {
  tournament,
  translationsSlice,
  oddsSlice,
  locationSlice,
} from "_store/reducers";
import type {
  Location,
  LocationActions,
  OddsActions,
  TournamentActions,
  TranslationActions,
} from "_store/types";
import { useConfig } from "lib/useConfig";

type QueryWithGame = {
  game: string;
};

const { fetch: fetchTournamentConfig } =
  tournament.actions as unknown as TournamentActions;
const { fetch: fetchTranslations } =
  translationsSlice.actions as unknown as TranslationActions;
const { fetch: fetchLocation } =
  locationSlice.actions as unknown as LocationActions;
const { fetch: fetchOdds } = oddsSlice.actions as unknown as OddsActions;

const useFetchStuff = () => {
  const dispatch = useDispatch();

  const hasLocation = useSelector<any, boolean>(getHasFetchedLocation);
  const hasOdds = useSelector<any, boolean>(getHasFetchedOdds);
  const hasConfig = useSelector<any, boolean>(getHasFetchedTourmentConfig);
  const hasTranslations = useSelector<any, boolean>(getHasFetchedTranslations);

  const location = useSelector<any, Location>((state) => state.location);

  const { locale, query } = useRouter();
  const { game: tournamentName } = query as QueryWithGame;

  const predictorConfig = useConfig();
  const tournamentId = predictorConfig?.tournamentId?.[tournamentName];

  useEffect(() => {
    if (tournamentId && !hasConfig) {
      dispatch(fetchTournamentConfig(tournamentId));
    }
    if (locale && !hasTranslations) {
      dispatch(fetchTranslations(locale));
    }

    if (!hasLocation) {
      dispatch(fetchLocation());
    }

    if (!hasOdds && hasLocation) {
      dispatch(fetchOdds(location));
    }
  }, [tournamentId, locale, location, hasLocation]);
};

export default useFetchStuff;
